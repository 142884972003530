'use client'
import styles from './error.module.css'
import buttons from "components/_styled/buttons.module.css";
import LazyImage from "components/LazyImage";

const Error500 = () => {

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>
        <h1>500 Error</h1>
        <p>Sorry, we have run into a problem on this page</p>
        <section className={styles.Links}>
            <a href="/search" className={buttons.primaryButton}>Try Another Search</a>
          <a  href="/contact" className={buttons.primaryButton}>
              Contact Us
            </a>
        </section>
      </div>
      <LazyImage
        alt="Sorry, we have run into a problem on this page"
        src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}error/500.png`}
        sources={[
          {
            srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}error/500.webp`,
            type: "image/webp",
          },
          {
            srcSet: `${process.env.NEXT_PUBLIC_APP_CDN_URL}error/500.png`,
            type: "image/png",
          },
        ]}
        layout="fill"
        objectFit="cover"
      />
    </div>
  );
};

export default Error500;
